import React, { Component } from 'react';
import Header from './Header';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import $ from "jquery";
import { Redirect } from 'react-router-dom';

import '../css/diffusion.css';
import { Trad } from '../trad-lib/index.bin';

export default class Diffusion extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.onChangeFormat = this.onChangeFormat.bind(this);
        this.onClickJour = this.onClickJour.bind(this);
        this.onClickTranche = this.onClickTranche.bind(this);
        this.state = {
            formatSelected: JSON.parse(localStorage.getItem("formatSelected") || '[]'),
            joursSelected: JSON.parse(localStorage.getItem("joursSelected") || '[]'),
            trancheSelected: JSON.parse(localStorage.getItem("trancheSelected") || '[]')
        }
    }
    onChangeFormat(event){
        this.setState({
            formatSelected: event.value
        },()=>{
            localStorage.setItem("formatSelected",JSON.stringify(event.value))
        })
    }
    onClickJour(jour: string){
        if($('#'+jour).hasClass('colorSelected')){
            $('#'+jour).removeClass('colorSelected');
        } else {
            $('#'+jour).addClass('colorSelected');
        }
        let joursSelected = this.state.joursSelected;
        if(joursSelected.includes(jour)){
            let index = joursSelected.indexOf(jour);
            joursSelected.splice(index, 1);
        } else {
            joursSelected.push(jour);
        }
        this.setState({
            joursSelected: joursSelected
        },()=>{
            localStorage.setItem("joursSelected",JSON.stringify(joursSelected))
        })
    }
    onClickTranche(tranche){
        if($('#'+tranche.id).hasClass('colorSelected')){
            $('#'+tranche.id).removeClass('colorSelected');
        } else {
            $('#'+tranche.id).addClass('colorSelected');
        }
        let trancheSelected = this.state.trancheSelected;
        let boolTranche = false;
        let index;
        trancheSelected.forEach((element, i) => {
            if(element.tranche === tranche.tranche){
                index = i;
                boolTranche = true;
            } else {
            }
        });
        if(boolTranche){
            trancheSelected.splice(index, 1);
        } else {
            trancheSelected.push(tranche);
        }
        this.setState({
            trancheSelected: trancheSelected
        },()=>{
            localStorage.setItem("trancheSelected",JSON.stringify(trancheSelected))
        })
    }
    componentDidMount(){
        const joursSelected = this.state.joursSelected;
        joursSelected.forEach((jour : any) => {
            $('#' + jour).addClass('colorSelected');
        });
        const trancheSelected = this.state.trancheSelected;
        trancheSelected.forEach((tranche : any) => {
            $('#' + tranche.id).addClass('colorSelected');
        });
        if(localStorage.getItem("formatSelected") === null){
            this.setState({
                formatSelected: "30 " + Trad("seconds")
            },()=>{
                localStorage.setItem("formatSelected",JSON.stringify("30 " + Trad("seconds")))
            })
        }
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        const dataListForm = [
            "30 " + Trad("seconds"),
            "40 " + Trad("seconds"),
            "50 " + Trad("seconds"),
        ];
        const dataJours = [
            Trad("mon"),
            Trad("tue"),
            Trad("wed"),
            Trad("thu"),
            Trad("fri"),
            Trad("sat"),
            Trad("sun"),
        ];
        const dataTrancheHoraire = [{
            tranche : "03:00 - 07:00", 
            id : "0307", 
        },{
            tranche : "07:00 - 09:00", 
            id : "0709", 
        },{
            tranche : "09:00 - 17:00", 
            id : "0917", 
        },{
            tranche : "17:00 - 22:00", 
            id : "1722", 
        }];
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div className="divTitle">
                        <p className="pResumePack">{Trad("diffusion")}</p>
                        <h1 className="titleResumePack">{Trad("diffusion_h1")}</h1>
                    </div>
                    <div className="mb-5">
                        <div className="divTitleCible mb-3">
                            Format
                        </div>
                        <DropDownList onChange={this.onChangeFormat} data={dataListForm} defaultValue={this.state.formatSelected} />
                    </div>
                    <div className="mb-5">
                        <div className="divTitleCible mb-3">
                            {Trad("days")}
                        </div>
                        <div className="d-flex divJourDis">
                            {
                                dataJours.map((jour : any , key : number) => (
                                    <div key={key} className="margeDivJour">
                                        <button onClick={() => this.onClickJour(jour)} className="btnCard">
                                            <div id={jour} className="divJours">
                                                {jour}
                                            </div>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div className="divTitleCible mb-3">
                            {Trad("diff_time")}
                        </div>
                        <div className="d-flex divTrancDis">
                            {
                                dataTrancheHoraire.map((tranche : any , key : number) => (
                                    <div key={key} className="margeDivJour">
                                        <button onClick={() => this.onClickTranche(tranche)} className="btnCard">
                                            <div id={tranche.id} className="divTranche">
                                                {tranche.tranche}
                                            </div>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev />
                        <ButtonContinue onDisabled={this.state.btnDisabled} />
                    </div>
                </div>
            </div>
        )
    }
}