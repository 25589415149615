import axios from "axios"
import { ITradType } from "./index.bin"

export class Client {

    static baseUrl = 'https://server.dev.adwone.com'
    static collection = 'rfi'

    static create(trads: ITradType[]) {
        // CREATE: ajout de trads
        return fetch(Client.baseUrl + '/Trad/' + this.collection,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(trads)
            })
            .then(res => res.json())
    }
    static read(): Promise<any> {
        return axios.get(this.baseUrl + '/trad/' +this.collection,{ headers: { Origin: "https://dev.adwone.com" }})
        .then((response:any) => {
            if(response.data.error){
              console.log(response)
            }else{
                return response
            }
           }).catch((err:any) => console.log(err.message)); 
    }

    static update(trads: ITradType[]): Promise<any> {
        return fetch(Client.baseUrl + '/Trad/' + this.collection,
            {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(trads)
            })
            .then(res => res.json())
    }

    static delete(trads: (Partial<ITradType> & { "@rid": string })[]): Promise<any> {
        return fetch(Client.baseUrl + '/Trad/' + this.collection,
            {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(trads)
            })
            .then(res => res.json())
    }
}