import * as React from 'react';
import { getter } from '@progress/kendo-react-common';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);

export const termsValidator = (value : any) => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value : any) => !value ?
    "Merci de renseigner  une adresse email" :
    (emailRegex.test(value) ? "" : "Merci de renseigner une adresse email valide");
export const mdpValidator = (value : any) => !value ?
    "Merci de renseigner un mot de passe" : "";
export const firstNameValidator = (value : any) => !value ?
    "Merci de renseigner votre prénom" : "";
export const lastNameValidator = (value : any) => !value ?
    "Merci de renseigner votre nom" : "";
export const societeValidator = (value : any) => !value ?
    "Merci de renseigner une société" : "";
export const addressValidator = (value : any) => !value ?
    "Merci de renseigner une adresse" : "";
export const postalCodeValidator = (value : any) => !value ?
    "Merci de renseigner le code postal" : "";
export const cityValidator = (value : any) => !value ?
    "Merci de renseigner la ville" : "";
export const countryValidator = (value : any) => !value ?
    "Merci de renseigner le pays" : "";
export const telValidator = (value : any) => !value ?
    "Merci de renseigner un numéro de téléphone" : "";
export const annonceurValidator = (value : any) => !value ?
    "Merci de renseigner un annonceur" : "";
export const nameValidator = (value : any) => !value ?
    "Full Name is required" :
    value.length < 7 ? "Full Name should be at least 7 characters long." : "";
export const userNameValidator = (value : any) => !value ?
    "User Name is required" :
    value.length < 5 ? "User name should be at least 3 characters long." : "";
export const phoneValidator = (value : any) => !value ?
    "" :
    phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const cardValidator = (value : any) => !value ?
    "Credit card number is required. " :
    ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = (value : any) => !value ?
    "CVC code is required," :
    cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = (value : any) => !value ?
    "Number of guests is required" :
    value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = (value : any) => value ? "" : "Number of Nights is required";
export const arrivalDateValidator = (value : any) => value ? "" : "Arriaval Date is required";
export const colorValidator = (value : any) => value ? "" : "Color is required.";
export const requiredValidator = (value : any) => value ? "" : "Error: This field is required.";
export const passwordValidator = (value : any) => value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
// export const addressValidator = (value : any) => value ? "" : "Address is required.";

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = (values : any) => {
    const userName = userNameGetter(values);
    const emailValue = emailGetter(values);

    if (userName && emailValue && emailRegex.test(emailValue)) {
        return {};
    }

    return {
        VALIDATION_SUMMARY: 'Please fill the following fields.',
        ['username']: !userName ? 'User Name is required.' : '',
        ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in valid format.'
    };
};