import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navbar from './Components/Navbar';
import Auth from './Components/Auth';
import Header from './Components/Header';
import Login from './Components/Login';
import Home from "./Components/Home";
import Country from "./Components/Country";
import Periode from "./Components/Periode";
import ResumePack from "./Components/ResumePack";
import ResumeUser from "./Components/ResumeUser";
import Validation from "./Components/Validation";
import Inscription from "./Components/Inscription";
import Audience from "./Components/Audience";
import Supports from "./Components/Supports";
import Budget from "./Components/Budget";
import Diffusion from "./Components/Diffusion";
import ResumeCamp from "./Components/ResumeCamp";
import PassForget from "./Components/ForgetPass";
import ConfirmInscription from "./Components/ConfirmInscription";
import { Trad, TradProvider } from './trad-lib/index.bin'
import ConditionGenerale from "./Components/ConditionGenerale";
import { Client } from './trad-lib/client.bin';
import RfiContext from './Components/RfiContext';
import ConfirmResetPassword from './Components/ConfirmResetPassword';
import ForgetPass from './Components/ForgetPass';
import ResetPassword from './Components/ResetPassword';
import ErrorPage from './Components/ErrorPage';
import ChangePasswordConfirm from './Components/ChangePasswordConfirm';

export default class App extends Component<any, any>{
  static contextType = RfiContext;
  constructor(props : any){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      language: localStorage.getItem('currentLocal') || 'fr',
      loaded : false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({loaded: true}), 500);
 }
  handleChange(event:any) {
    this.setState({language: event.target.value});
}
  render(){
    const contextValue = {
      handleChange:this.handleChange,
      language: this.state.language
    } 
    localStorage.setItem('currentLocal',this.state.language)
    
    TradProvider.GetInstance().Init()
      return (
        this.state.loaded &&
        <RfiContext.Provider value={contextValue}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/inscription" component={Inscription} />
            <Route exact path="/forgetpass" component={ForgetPass} />
            <Route exact path="/404" component={ErrorPage} />
            <Route exact path="/passwordchanged" component={ChangePasswordConfirm} />
            <Route exact path="/confirmInscription" component={ConfirmInscription} />
            <Route exact path="/confirmpassword" component={ConfirmResetPassword} />
            <Route exact path="/resetpassword/:token" component={ResetPassword} />
            <Route exact path="/conditionsGenerales" component={ConditionGenerale} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/country" component={Country} />
            <Route exact path="/periode" component={Periode} />
            <Route exact path="/audience" component={Audience} />
            <Route exact path="/package/resume" component={ResumePack} />
            <Route exact path="/package/resume/user" component={ResumeUser} />
            <Route exact path="/package/validation" component={Validation} />
            <Route exact path="/campagne/supports" component={Supports} />
            <Route exact path="/campagne/Budget" component={Budget} />
            <Route exact path="/campagne/Diffusion" component={Diffusion} />
            <Route exact path="/campagne/resume" component={ResumeCamp} />
            <Route exact path="/campagne/resume/user" component={ResumeUser} />
            <Route exact path="/campagne/validation" component={Validation} />
          </Switch>
          </div>
        </RfiContext.Provider>

      );
  }
}
