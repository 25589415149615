import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import axios from "axios";
import imgLogin from '../images/arbre.png';
import Header from './Header';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {FormInput} from './form-components';
import {emailValidator, firstNameValidator, lastNameValidator, cityValidator, countryValidator} from './Validators';
import {mdpValidator, addressValidator,postalCodeValidator,telValidator,societeValidator} from './Validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faDigitalTachograph as faTv } from '@fortawesome/free-solid-svg-icons'
import { faFilm as faCinema } from '@fortawesome/free-solid-svg-icons'
import { faDesktop} from '@fortawesome/free-solid-svg-icons'
import PasswordStrengthBar from 'react-password-strength-bar';

import '../css/inscription.css';
import $ from "jquery";
import { Trad } from "../trad-lib/index.bin";
import RightSideBar from "./RightSideBar";

interface Props {
    name?: any;
    value?: any;
}
interface State {
    email:string,
    firstName:string,
    lastName:string,
    password:string,
    tel: string,
    passwordConf:string,
    society: string,
    fonction: string,
    domain: string,
    address:string,
    postalCode:string,
    city:string,
    country:string,
    errors : {
        email: string,
        password: string
    },
    mediaList : any[],
    redirectInscription: boolean
}

export default class Inscription extends Component<Props, State> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props : Props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handCheckbox = this.handCheckbox.bind(this);


        this.state = {
            email: localStorage.getItem('email'),
            firstName:localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
            passwordConf: localStorage.getItem('password') ? localStorage.getItem('password') : '',
            tel: localStorage.getItem('tel'),
            fonction: localStorage.getItem('fonction'),
            society:localStorage.getItem('society'),
            domain:localStorage.getItem('domain'),
            address:localStorage.getItem('address'),
            postalCode:localStorage.getItem('postalCode'),
            city:localStorage.getItem('city'),
            country:localStorage.getItem('country'),
            mediaList : [],
            errors: {
                email: '',
                password: ''
            },
            redirectInscription: false
        };
    }

    createField(fieldName:string,label:string,type:string,handle:any,validator?:any,option?:any){
        return (
            <div className="form-group registerForm">
                <label htmlFor={fieldName} className="">{Trad(label)}<span className="optionText"> {option}</span></label>
                 <Field
                    id={fieldName}
                    name={fieldName}
                    className={'form-control'}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    // defaultValue={this.state[fieldName]}
                    onChange={handle}
                    validator={validator}
                />
            </div>
            )
    }
    createRadioField(fieldName:string,label:string,type:string,handle:any,validator?:any,option?:any){
        return (
            <div className="row radioDiv">
                 <Field
                    // id={fieldName}
                    name={fieldName}
                    className={''}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    // defaultValue={this.state[fieldName]}
                    onChange={handle}
                    validator={validator}
                />
                <label htmlFor={fieldName} className="radioLabel">{Trad(label)}<span className="optionText"> {option}</span></label>
            </div>
            )
    }
    createCheckbox(fieldName:string,icon:any){
        $('.icon svg').removeClass('fa-w-16');
        $('.icon svg').addClass('fa-w-20');

        return (
            <div className="input-container">
                <input id={fieldName} value={this.state.mediaList[fieldName]} onChange={this.handCheckbox}  className="checkbox-button" type="checkbox" name={fieldName} />
                <div className="checkbox-title">
                    <div className="icon">
                    <FontAwesomeIcon className="inco" icon={icon} />
                    </div>
                    <label htmlFor={fieldName} className="checkbox-title-label">{fieldName}<span> </span></label>
                </div>
            </div>
        )
    }
    handCheckbox(e: any) {
        let mediaList = this.state.mediaList;
        const { name, checked } = e.target
        if(checked){
            this.setState({
                mediaList: [...this.state.mediaList, name]
            })
        }else{
            var index = mediaList.indexOf(name);
            if (index > -1) {
                mediaList.splice(index, 1);
                this.setState({
                    mediaList
                })
            }
        }

        console.log(this.state.mediaList);
    }
    handleChange(event: any) {
        const target = event.target as any;
        console.log(target);
        this.setState({ [target.name]: target.value } as State,()=>{
            localStorage.setItem(target.name, this.state[target.name])
        });
    }
    handleSubmit () {
        const url = `${this.baseUrl}/auth/signup`
        if(this.state.password !== this.state.passwordConf){
            $('.inputMdp').css('border', '1px solid red');
            $('.messErrorMdp').css('display', 'block');
            $('.inputEmailInsc').css('border', '1px solid #BBBBBB');
            $('.messErrorEmail').css('display', 'none');
        } else {
            $('.inputMdp').css('border', '1px solid #BBBBBB');
            $('.messErrorMdp').css('display', 'none');
            const data = {
                email: this.state.email, 
                password: this.state.password,
                tel: this.state.tel,
                fonction: this.state.fonction,
                society: this.state.society,
                domain:this.state.domain,
                address:this.state.address,
                postalCode:this.state.postalCode,
                city:this.state.city,
                country:this.state.country,
                medliaList:this.state.mediaList
            };
            // console.log(data);return;
            axios
                .post(url, data)
                .then((response) => {
                    console.log(response.data);
                    if(response.data.error){
                        $('.messErrorLogin').css('display', 'block');
                    }else{
                        this.setState({
                            redirectInscription: true
                        },()=>{
                            let trad = localStorage.getItem('all-trads');
                            let currentLocal = localStorage.getItem('currentLocal');
                            localStorage.clear();
                            localStorage.setItem('all-trads',trad);
                            localStorage.setItem('currentLocal',currentLocal);  
                        })
                    }
                }).catch((err) =>
                {
                    $('.inputEmailInsc').css('border', '1px solid red');
                    $('.messErrorEmail').css('display', 'block');
                }
            );
            const dataMail = {
                email: this.state.email
            };
 
        }
    }
    render() {
        if (this.state.redirectInscription){
            return (<Redirect to={'/confirmInscription'}/>)
        }
        if (sessionStorage.getItem('userData')){
            return (<Redirect to={'/periode'}/>)
        }
        return (

            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        <div className="d-flex justify-content-between">
                            <h1 className="titleInsc">{Trad("txt_register")} </h1>
                            <h5><a className="linkInscri" href="/">{Trad("txt_login_h")}</a></h5>
                        </div>

                        <div className="messErrorLogin">
                            <div>
                                <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; &nbsp;{Trad("txt_email_exist_err")}
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                email: localStorage.getItem('email'),
                                firstName:localStorage.getItem('firstName'),
                                lastName: localStorage.getItem('lastName'),
                                password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                                passwordConf: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                                tel: localStorage.getItem('tel'),
                                fonction: localStorage.getItem('fonction'),
                                society:localStorage.getItem('society'),
                                domain:localStorage.getItem('domain'),
                                address:localStorage.getItem('address'),
                                postalCode:localStorage.getItem('postalCode'),
                                city:localStorage.getItem('city'),
                                country:localStorage.getItem('country'),
                            }}
                            onSubmit={this.handleSubmit}
                            render={(formRenderProps: any) => (
                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <div className="form-row">
                                            <div className="col">
                                                {this.createRadioField('gender','Madame','radio',this.handleChange)}
                                            </div>
                                            <div className="col">
                                                {this.createRadioField('gender','Monsieur','radio',this.handleChange)}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                {this.createField('lastName','Nom','text',this.handleChange,lastNameValidator)}
                                            </div>
                                            <div className="col">
                                                {this.createField('firstName','Prénom','text',this.handleChange,firstNameValidator)}
                                            </div>
                                        </div>
                                        {this.createField('email','Adresse e-mail','email',this.handleChange,emailValidator)}
                                        <div className="messErrorEmail">
                                            {Trad("txt_email_exist_err")}
                                        </div>
                                        {this.createField('tel','Téléphone','number',this.handleChange,telValidator)}

                                        {this.createField('password','txt_pass_label','password',this.handleChange,mdpValidator)}
                                        <PasswordStrengthBar 
                                        barColors={['#ddd','#ef4836', '#747744','#f6b44d', '#25c281']} 
                                        scoreWords={['Faible','Faible', 'moyen','Bon', 'Excellent']} 
                                        shortScoreWord="trop court" 
                                        password={this.state.password}
                                        minLength={6} 
                                        />

                                        {this.createField('passwordConf','txt_confirm_pass','password',this.handleChange)}

                                        <div className="messErrorMdp">
                                            {Trad("txt_pass_match_err")}
                                        </div>
                                        <div className="mt-2">
                                            <h3 className="font-weight-bold text-danger">{Trad("Votre société")} </h3>
                                        </div>
                                        {this.createField('society','Société','text',this.handleChange,societeValidator)}

                                        {this.createField('domain','Domaine','text',this.handleChange,'','facultatif')}

                                        {this.createField('fonction','Fonction','text',this.handleChange,'','facultatif')}

                                        {this.createField('address','Adresse','text',this.handleChange,addressValidator)}

                                        <div className="form-row">
                                            <div className="col">
                                                {this.createField('postalCode','Code postal','text',this.handleChange,postalCodeValidator)}
                                            </div>
                                            <div className="col">
                                                {this.createField('city','Ville','text',this.handleChange,cityValidator)}
                                            </div>
                                        </div>
                                        {this.createField('country','Pays','text',this.handleChange,countryValidator)}

                                        {/* <h5>Je suis intéressé par <span className="optionText"> facultatif</span> </h5>
                                        <div className="container">
                                            <div className="checkbox-title-group">
                                                {this.createCheckbox('tv',faTv)}

                                                {this.createCheckbox('radio',faTv)}

                                                {this.createCheckbox('cinema',faCinema)}
                                                
                                                {this.createCheckbox('digital',faDesktop)}
                                            </div>
                                        </div> */}
                                        <div className="divInscSubmit">
                                            <p className="pInsc">{Trad("txt_condition_1")} <a className="spanInsc" href="/conditionsGenerales">{Trad("txt_condition_2")}</a></p>
                                            <button name="btnSubmit" type={'submit'} disabled={!formRenderProps.allowSubmit} className="btnSubmitLogin">{Trad("txt_register")}</button>
                                        </div>
                                    </fieldset>
                                </FormElement>
                            )}
                        />
                        <div className="divBottom">

                        </div>
                    </div>

                </div>
            </div>
        )
    }

}
