import { Client } from "./trad-lib/client.bin";
import { Trad as TradCode, TradClassName, TradProvider } from "./trad-lib/index.bin";

let base = Date.prototype.toJSON;
export type TradMultiLang = {
    "fr-FR": string;
    "en-US": string;
}
export class Trad  {
    "@rid"?: string; // ID de la DB
    Type: "dynamic" | "static";
    Class?: string;
    Property?: string;
    Value?: string;
    Code?: string;
    Translations: TradMultiLang;
}

Date.prototype.toJSON = function (...args) {

    let date = new Date(this);
    date.setHours(0, 0, 0, 0);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let copy = new Date(date.getTime() - userTimezoneOffset);

    let result = base.apply(copy, args);
    return result;
}



type StorageTrads = {
    expires: string;
    Trads: Trad[];
};

let keyStoreTrads = 'all-trads';

function GetStorageTrad() {

    let stored = localStorage.getItem(keyStoreTrads);
    if (stored) {
        let storageTrads: StorageTrads = <StorageTrads>JSON.parse(stored);
        if (storageTrads) {
            let expiration = new Date(storageTrads.expires);
            if (new Date().getTime() < expiration.getTime()) {
                return storageTrads.Trads;
            }
        }
    }
}

function SetStorageTrad(trads: Trad[]) {
    let expires = new Date();
    expires.setMinutes(expires.getMinutes() + 10);

    let storageTrads: StorageTrads = {
        expires: expires?.toString(),
        Trads: trads
    }

    localStorage.setItem(keyStoreTrads, JSON.stringify(storageTrads));
}


TradProvider.TradGetter = async () => {
    let trads: Trad[] = undefined;

    let stored: Trad[] = GetStorageTrad();
    if (stored)
        return stored;

    trads = (await Client.read()).data.results;
    SetStorageTrad(trads);

    return trads;
}