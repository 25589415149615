import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';
import $ from "jquery";

import '../css/buttonContinue.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';

export default class ButtonContinue extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            redirect: false,
        }
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to={{pathname:this.props.redirect}}/>)
        }
        return (
            <div className="divBtn">
                <button name="btnSuiv" onClick={() => this.setState({redirect:true}) } className={this.props.onDisabled ? "btnSuiv disable" : "btnSuiv"} disabled={this.props.onDisabled ? true : false}>
                    {
                        this.props.skip ?
                        Trad("Passer cette étape")
                        : 
                        Trad("next_button") 

                    }
                    <FontAwesomeIcon className="iconCont" icon={faArrowRight} />
                </button>
            </div>
        )
    }
}