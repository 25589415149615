import React, { Component } from 'react';
import Header from './Header';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Card, CardTitle, CardBody, CardActions } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import $, { support } from "jquery";
import { Redirect } from 'react-router-dom';

import '../css/supports.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';

export default class Supports extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props : any){
        super(props);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onChangeOrder = this.onChangeOrder.bind(this);
        this.onClickCard = this.onClickCard.bind(this);
        this.state = {
            tabSuppSelected: JSON.parse(localStorage.getItem("tabSuppSelected") || '[]'),
            filtering: false,
            dataFilter: [],
            inputFilter: "",
            regionFilter: "",
            nbSupp: 0,
            btnDisabled: true,
            supports : JSON.parse(localStorage.getItem("supports") || '[]')
        }
    }
    onChangeFilter(event){
        let inputFilter = "";
        let regionFilter = "";
        if(event.value === undefined){
            inputFilter = event.target.value;
            regionFilter = this.state.regionFilter;
        } else {
            inputFilter = this.state.inputFilter;
            if(event.value === "Toutes"){
                regionFilter = "";
            } else {
                regionFilter = event.value;
            }
        }
        const result = filterBy(this.state.supports, {
            logic: 'and',
            filters: [
                { field: "Region", operator: "contains", value: regionFilter },
                { field: "STCK_LIB", operator: "contains", value: inputFilter, ignoreCase: true }
            ]
        });
        this.setState({
            dataFilter: result,
            filtering: true,
            regionFilter: regionFilter,
            inputFilter: inputFilter
        });
    }
    onChangeOrder(event){
        if(this.state.dataFilter.length === 0){
            if(event.target.value === "Nom"){
                const result = orderBy(this.state.supports, [{ field: "STCK_LIB", dir: "asc" }]);
                this.setState({
                    supports: result
                })
            }
        } else {
            if(event.target.value === "Nom"){
                const result = orderBy(this.state.dataFilter, [{ field: "STCK_LIB", dir: "asc" }]);
                this.setState({
                    dataFilter: result
                })
            }
        }
    }
    onClickCard(id){
        if($('#'+id+' .k-card').hasClass('cardSelectedSupp')){
            $('#'+id+' .k-card').removeClass('cardSelectedSupp');
            $('#'+id+' .k-card .k-card-body .pCardSupp').removeClass('pcardSelectedSupp');
        } else {
            $('#'+id+' .k-card').addClass('cardSelectedSupp');
            $('#'+id+' .k-card .k-card-body .pCardSupp').addClass('pcardSelectedSupp');
        }
        let elemSelect = {STCK_ID:0};
        this.state.supports.forEach(element => {
            if(element.STCK_ID === id){
                elemSelect = element;
            }
        });
        // console.log(elemSelect)
        let tabSuppSelected = this.state.tabSuppSelected;
        let boolPush = true;
        let indexTab = 0;
        if(tabSuppSelected.length === 0){
            tabSuppSelected.push(elemSelect);
        } else {
            for (let index = 0; index < tabSuppSelected.length; index++) {
                if(tabSuppSelected[index].STCK_ID === elemSelect.STCK_ID){
                    indexTab = index;
                    tabSuppSelected.splice(indexTab, 1);
                    boolPush = false;
                    break;
                }
            }
            if(boolPush){
                tabSuppSelected.push(elemSelect);
            }
        }
        const nbSupp = tabSuppSelected.length;
        let btnBool = true;
        if(nbSupp > 0){
            btnBool = false;
        }
        this.setState({
            tabSuppSelected: tabSuppSelected,
            nbSupp: nbSupp,
            btnDisabled: btnBool
        },()=>{
            localStorage.setItem("tabSuppSelected",JSON.stringify(tabSuppSelected))
        })
    }
    componentDidMount(){
        var start_time = new Date().getTime();
        const data = {
            "filter": {
                "PRM_LIST_ANTN_ID": "",
                "PRM_LIB_LIKE": "%%",
                "PRM_LIST_ID": null,
                // "PRM_LIST_PAYS_ID": "3",
                "PRM_PUBLIABLE_BRIEF": 1,
                "PRM_VALIDE": 1,
                "NoLinks": 0
            }
        }
        axios
        .post(`${this.baseUrl}/support`,data)
        .then((response) => {
          if(response.data.error){
            console.log(response)
          }else{
              localStorage.setItem('supports',JSON.stringify(response.data.data.GetManyResult));
              this.setState({
                supports: JSON.parse(localStorage.getItem('supports') || '[]')
              })
              var request_time = new Date().getTime() - start_time;
              console.log(request_time);

          }
         }).catch((err) => console.log(err.message));

        const nbSupp = this.state.tabSuppSelected.length;
        let btnBool = true;
        if(nbSupp > 0){
            btnBool = false;
        }
        this.setState({
            nbSupp: nbSupp,
            btnDisabled: btnBool
        })
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        const dataRegions = [
            Trad("north"),
            Trad("east"),
            Trad("south"),
            Trad("west"),
        ];
        const dataOrder = [
            Trad("name")
        ];
        let supports = [];
        if(this.state.filtering){
            supports = this.state.dataFilter;
        } else {
            supports = this.state.supports;
        }
        let tabIdSelected = [];
        this.state.tabSuppSelected.forEach(element => {
            tabIdSelected.push(element.STCK_ID)
        });
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div className="divTitle">
                        <p className="pResumePack">{Trad("supports")}</p>
                        <h1 className="titleResumePack">{Trad("support_h1")}</h1>
                    </div>
                    <div className="d-flex justify-content-between align-items-center divSuppResp">
                        <div className="d-flex align-items-center divSuppRespSearch">
                            <div>
                                <div className="divTitSuppSelect">
                                    {Trad("search_support")}
                                </div>
                                <div className="divInputSearch d-flex align-items-center">
                                    <input onChange={this.onChangeFilter} className="inputSearchSup" type="text"/>
                                    <FontAwesomeIcon className="iconSearch" icon={faSearch} />
                                </div>
                            </div>
                            <div>
                                <div className="divTitSuppSelect">
                                {Trad("region")}
                                </div>
                                <div>
                                    <DropDownList onChange={this.onChangeFilter} className="styleSelectRegion" data={dataRegions} defaultItem={Trad("all")} />
                                </div>
                            </div>
                        </div>
                        <div className="align-items-center">
                            <div className="divTitSuppSelect">
                                &nbsp;
                            </div>
                            <div>
                                <DropDownList onChange={this.onChangeOrder} className="styleSelectOrder" data={dataOrder} defaultValue={Trad("support_filter")} />
                            </div>
                        </div>
                    </div>
                    <div className="divSuppSelected">
                        {
                            this.state.nbSupp > 1 ?
                                this.state.nbSupp + ' ' + Trad("n_support_p")
                            :
                                this.state.nbSupp + ' ' + Trad("n_support_s")
                        }
                    </div>
                    <div className="row mt-4 rowSupp">
                        {
                            supports.map((supp : any , key : number) => (
                                <div id={supp.STCK_ID} key={key} className="columnSupp">
                                    <button onClick={() => this.onClickCard(supp.STCK_ID)} className="btnCard">
                                        <Card className={tabIdSelected.includes(supp.STCK_ID)? "cardSelectedSupp cardSize" : "cardSize"}>
                                            <CardBody>
                                                <CardTitle className="cardTitle">
                                                    <img className="w-100" src={`../images/replace.jpg`} alt="Logo Support"/>
                                                </CardTitle>
                                                <p className={tabIdSelected.includes(supp.STCK_ID)? "pCardSupp pcardSelectedSupp" : "pCardSupp"}>{supp.STCK_LIB}</p>
                                            </CardBody>
                                        </Card>
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                    <div className="d-flex divBtnRfp">
                        <div className="divEmpty"> &nbsp; </div>
                        <ButtonContinue onDisabled={this.state.btnDisabled} />
                    </div>
                </div>
            </div>
        )
    }
}