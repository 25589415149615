import React, { Component } from 'react';
import { Stepper } from '@progress/kendo-react-layout';
import $ from "jquery";

import '../css/stepperLine.css';
import { Trad } from '../trad-lib/index.bin';

export default class StepperLine extends Component<any, any> {
    constructor(props: any) {
        super(props);
        // this.state = {
        //     numStep: localStorage.getItem('stepUSer')
        // };
    }

    addCheck(){
        $('.k-stepper .k-step-done .k-step-indicator .k-step-indicator-text').css('display', 'none');
        $('.k-stepper .k-step-done .k-step-indicator').append("<span class='system-checkmark'>");
    }
    componentDidMount(){
        this.addCheck();
    }
    render() {
        const tabStepsCamp = [
            {
                label: 'Supports'
            },
            {
                label: 'Budget'
            },
            {
                label: Trad('diffusion')
            },
            {
                label: Trad('resume')
            },
        ];
        const path = window.location.pathname;
        const pathCamp = ["/campagne/supports", "/campagne/budget", "/campagne/diffusion", "/campagne/resume", "/campagne/resume/user"];
        let stepCamp : boolean = false;
        if(pathCamp.includes(path)){
            stepCamp = true;
        }
        return (
            <div className="styleStep">
                {
                        stepCamp?
                            <Stepper
                                value={parseInt(this.state.numStep) === 4 ? 3 : parseInt(this.state.numStep)}
                                items={tabStepsCamp}
                                linear={true}
                            />
                        :
                            ""
                }
            </div>
        )
    }
}