import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import '../css/buttonPrev.css';
import { Trad } from '../trad-lib/index.bin';
import { Redirect } from 'react-router-dom';

export default class ButtonPrev extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            redirect: false,
        }
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to={{pathname:this.props.redirect}}/>)
        }
        return (
            <div className="divBtnPrev">
                <button name="btnPrev" onClick={event => this.setState({redirect:true})} className="btnPrev"><FontAwesomeIcon className="iconPrev" icon={faArrowLeft} />&nbsp; {Trad("prev_button")}</button>
            </div>
        )
    }
}