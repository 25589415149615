import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import imgLogin from '../images/arbre.png';
import Header from './Header';

import '../css/confirmInscription.css';

export default class ErrorPage extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.onClickStart = this.onClickStart.bind(this);
        this.state = {
            redirect: false,
        };
    }

    onClickStart(){
        this.setState({
            redirect: true
        });
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to={"/"}/>)
        }
        return (
            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        <h1 className="titleConfirmInsc">404 Page Not Found.</h1>
                        <div className="divConfirmTitle">
                            404 Page Not Found.
                        </div>
                        <div className="divBtnConfirmInsc">
                            <button name="btnStart" onClick={this.onClickStart} className="btnSuiv">Login</button>
                        </div>
                    </div>
                    <div className="divRight">
                        <img className="imgLogin" src={imgLogin} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
