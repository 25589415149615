import * as React from 'react';
import { Loader, LoaderType } from '@progress/kendo-react-indicators';

const initialType: LoaderType = 'converging-spinner';

export default class ReactLoader extends React.Component {

  state = {
    type: initialType
  }
  render() {
    return (
      <div className="example">
        <div className="example-wrap">
          <br />
          <div className='row mt-5' style={{width: '100%'}}>
            <div className='col-12 text-center'>
              <Loader size='large' type={this.state.type} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}