import React, { Component } from 'react';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip } from '@progress/kendo-react-charts';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import { Redirect } from 'react-router-dom';

import '../css/resumeCamp.css';
import { Trad } from '../trad-lib/index.bin';

export default class ResumeCamp extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            package: [],
            cibles: JSON.parse(localStorage.getItem("cibleSelect") || '[]'),
            listCible: JSON.parse(localStorage.getItem("listCible") || '[]'),
        }
    }
    convertDate(inputFormat : any) {
        function pad(s:any) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        const cStartDate = localStorage.getItem("cStartDate");
        const cEndDate = localStorage.getItem("cEndDate");
        const formatSelected = JSON.parse(localStorage.getItem("formatSelected") || '[]');
        const joursSelected = JSON.parse(localStorage.getItem("joursSelected") || '[]');
        const trancheSelected = JSON.parse(localStorage.getItem("trancheSelected") || '[]');
        const priceTot = JSON.parse(localStorage.getItem("priceTot") || '0');
        const devise = JSON.parse(localStorage.getItem("devise") || '0');
        const suppSelected = JSON.parse(localStorage.getItem("tabSuppSelected") || '[]');
        const colorDonuts =[
            "#6B1928", "#AB273F", "#EB3656", "#ED8699", "#FAC9D1"
        ]
        let j = 0;
        let budPourcent = 0;
        suppSelected.forEach((supp) => {
            if(supp.budget === undefined){
                supp.budget = 0;
            }
            supp.color = colorDonuts[j];
            supp.budGraph = priceTot * supp.budget / 100;
            budPourcent += parseInt(supp.budget);
            if(j === 4){
                j = 0;
            } else {
                j++;
            }
        });
        const nbSuppSelected = suppSelected.length;
        const resteBudget = {
            STCK_LIB: "Reste",
            budGraph: priceTot * (100 - budPourcent) / 100,
            color: '#000000'
        };
        suppSelected.push(resteBudget);
        const renderTooltip = (context: any) => {
            const { dataItem, value } = context.point || context;
            return (<div>{dataItem.STCK_LIB} : {value} {devise} </div>);
        };
        const cibleLabel = this.state.cibles.map((cible : number)=>{
            return this.state.listCible.find((list:any)=>{
                return list.CMKT_ID == cible;
            });
        })
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div className="divTitle">
                        <p className="pResumePack">{Trad("resume")}</p>
                        <h1 className="titleResumePack">{Trad("resume_h1")}</h1>
                    </div>
                    <div className="d-flex divResCampDis">
                        <div className="borderResume campResumeCamp overflow-auto">
                            <h2 className="titleResumePackCamp">{Trad("resume_h2")}</h2>
                            <div className="d-flex mt-5">
                                <div className="divIcon"><FontAwesomeIcon icon={faCalendar} /></div>
                                <div className="divDate">
                                    <div className="labelDate">{Trad("start")}</div>
                                    <div className="dateStyle">{this.convertDate(cStartDate)}</div>
                                </div>
                                <div className="divDate">
                                    <div className="labelDate">{Trad("end")}</div>
                                    <div className="dateStyle">{this.convertDate(cEndDate)}</div>
                                </div>
                            </div>
                            <div className="d-flex mt-5">
                                <div className="divIcon"><FontAwesomeIcon icon={faClock} /></div>
                                <div className="divDate">
                                    <div className="labelDate">Format</div>
                                    <div className="dateStyle">{formatSelected}</div>
                                </div>
                                <div className="divDate">
                                    <div className="labelDate">{Trad("days")}</div>
                                    {
                                        joursSelected.map((jour : any , key : number) => (
                                            <span key={key} className="dateStyle">
                                                {jour + " "}
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="d-flex mt-3">
                                <div className="divIcon"></div>
                                <div className="divTanche">
                                    <div className="labelDate">{Trad("diff_time")}</div>
                                    {
                                        trancheSelected.map((tranche : any , key : number) => (
                                            <span key={key} className="dateStyle">
                                                {tranche.tranche + " "}
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="d-flex mt-5">
                                <div className="divIcon"><FontAwesomeIcon icon={faUserCircle} /></div>
                                <div className="divTanche">
                                    <div className="labelDate">{Trad("audience")}</div>
                                    {
                                        cibleLabel.map((cible : any , key : number) => (
                                            <div key={key} className="dateStyle">
                                                - Cible {cible.CMKT_LIB}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="borderResume campResumeCamp">
                            <h2 className="titleResumePackCamp">{Trad("budget")}</h2>
                            <div className="d-flex mt-5">
                                <div className="divBudRes">
                                    <div className="labelDate">{Trad("budget")}</div>
                                    <div className="dateStyle">{priceTot + " " + devise}</div>
                                </div>
                                <div className="divBudRes">
                                    <div className="labelDate">{Trad("supports")}</div>
                                    <div className="dateStyle">{nbSuppSelected}</div>
                                </div>
                            </div>
                            <Chart>
                                <ChartTooltip render={renderTooltip} />
                                <ChartSeries>
                                    <ChartSeriesItem size={30} holeSize={115} sizeField={"20"} type="donut" name={suppSelected} data={suppSelected} categoryField="Nom" field="budGraph" />
                                </ChartSeries>
                                <ChartLegend visible={false} />
                            </Chart>
                        </div>
                        <div className="borderResume campResumeCamp mr-0">
                            <div className="d-flex divMargTitle">
                                <h2 className="titleResumePackCamp">{Trad("res_support")}</h2>
                                <div className="mt-1">
                                    &nbsp; ({nbSuppSelected})
                                </div>
                            </div>
                            <div className="overflow-auto divSuppCampRes">
                                {
                                    suppSelected.map((supp : any , key : number) => (
                                        supp.STCK_LIB !== "Reste" ?
                                            <div key={key} className="d-flex align-items-center mb-3">
                                                <div className="divImgResCamp"><img className="imgResCamp" src={`../images/replace.jpg`} alt="Logo Support"/></div>
                                                <div className="divSupportRes">
                                                    <div className="dateStyle">{supp.STCK_LIB}</div>
                                                    <div className="labelDate">{supp.Region}</div>
                                                </div>
                                            </div>
                                        :
                                        <div key={key}></div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev />
                        <ButtonContinue onDisabled={this.state.btnDisabled} />
                    </div>
                </div>
            </div>
        )
    }
}