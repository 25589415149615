import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormRadioGroup } from './form-components';
import { societeValidator, emailValidator, phoneValidator } from './Validators';
import $ from "jquery";

import '../css/modalCoordonees.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';

export default class ModalCoordonees extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    
    constructor(props : any){
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitModal = this.handleSubmitModal.bind(this);
        this.state = {
            userData: JSON.parse(sessionStorage.getItem('userData') || '[]')
        };
    }
    handleClose(){
        this.props.onCurrentModalShow();
    }
    handleSubmitModal(data:any){
        const id = this.state.userData.data._id;
        data.id = id;
        axios
        .put(`${this.baseUrl}/users`,data)
        .then((response) => {
          console.log(response.data)
          if(response.data.error){
              $('.messErrorLogin').css('display', 'block');
          }else{
            console.log(response.data)
            sessionStorage.setItem('userData',JSON.stringify(response.data));
            this.setState({
                userData: JSON.parse(sessionStorage.getItem('userData') || '[]')
            })
            this.props.onCurrentModalShow();
          }
         }).catch((err) => 
            $('.messErrorLogin').css('display', 'block')
        );
    }
    render() {
        const dataInputRadio = [
            { label: Trad('mr'), value: Trad('mr')},
            { label: Trad('madam'), value: Trad('madam') }
        ];
        return (
            <div>
                <Modal centered={true} show={this.props.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="titleModalCoor">{Trad("contact_details")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={this.handleSubmitModal}
                            initialValues={{
                                sexe: dataInputRadio[0].value,
                                firstName: this.state.userData.data.firstName,
                                society: this.state.userData.data.society,
                                email: this.state.userData.data.email,
                                lastName: this.state.userData.data.lastName,
                                position: this.state.userData.data.position,
                                telephone: this.state.userData.data.telephone
                            }}
                            render={(formRenderPropsModal: any) => (
                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <div className="divRadio">
                                            <Field
                                                id={'sexe'}
                                                name={'sexe'}
                                                component={FormRadioGroup}
                                                data={dataInputRadio}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <div className="w-50 pr-3">
                                                <Field
                                                    id={'prenom'}
                                                    name={'firstName'}
                                                    label={Trad("first_name")}
                                                    placeholder={Trad("first_name")}
                                                    component={FormInput}
                                                />
                                                <Field
                                                    id={'societe'}
                                                    name={'society'}
                                                    label={Trad("company")}
                                                    placeholder={Trad("company")}
                                                    component={FormInput}
                                                    validator={societeValidator}
                                                />
                                                <Field
                                                    id={'email'}
                                                    name={'email'}
                                                    label={'Email *'}
                                                    type={'email'}
                                                    placeholder={'Email'}
                                                    component={FormInput}
                                                    validator={emailValidator}
                                                />
                                            </div>
                                            <div className="w-50 pl-3">
                                                <Field
                                                    id={'nom'}
                                                    name={'lastName'}
                                                    label={Trad("last_name")}
                                                    placeholder={Trad("last_name")}
                                                    component={FormInput}
                                                />
                                                <Field
                                                    id={'poste'}
                                                    name={'position'}
                                                    label={Trad("poste")}
                                                    placeholder={Trad("poste")}
                                                    component={FormInput}
                                                />
                                                <Field
                                                    id={'telephone'}
                                                    name={'telephone'}                                               
                                                    label={Trad("phone")}
                                                    placeholder={'06 00 00 00 00'}
                                                    // mask={'(999) 000-00-00-00'}
                                                    component={FormInput}
                                                    validator={phoneValidator}
                                                />
                                            </div>
                                        </div>
                                        <div className="divChampsObligModal">
                                            * {Trad("req_field")}
                                        </div>
                                        <div className="k-form-buttons text-right d-block mt-4">
                                            <button className="btnAnnule" type="button" onClick={this.handleClose}>{Trad("cancel")}</button>
                                            <button className="btnValider" type='submit' disabled={!formRenderPropsModal.allowSubmit}>{Trad("validate")}</button>
                                        </div>
                                    </fieldset>
                                </FormElement>
                            )}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}