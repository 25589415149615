import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trad} from '../trad-lib/index.bin';
import Header from './Header';
import "react-datepicker/dist/react-datepicker.css";
import '../css/periode.css';
import Carousel from 'react-multi-carousel';
export default class Country extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false,
            countries: [
                {
                    id: 3,
                    name: {
                        fr: "Côte d'ivoire",
                        en: "Ivory Coast"
                    },
                    flag: "../images/ivorycost.png"
                },
                {
                    id: 11,
                    name: {
                        fr: "Cameroun",
                        en: "Cameroon"
                    },
                    flag: "../images/cameroon.png"
                }
            ]
        };
    }
    handleChangeStart = (event: any) => {
        this.setState({
            country_selected: event.target.value,
            redirect: true
        },()=>{
            localStorage.setItem("country_id_selected",event.target.value)
        });
    }
    componentDidMount() {

        localStorage.setItem('stepUSer', JSON.stringify(0));
    }
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 1900 },
              items: 4
            },
            desktop: {
              breakpoint: { max: 1900, min: 1424 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1424, min: 950 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 950, min: 0 },
              items: 1
            }
        };
        if (!sessionStorage.getItem('userData')) {
            return (<Redirect to={'/'} />)
        }
        if(this.state.redirect){
            return (<Redirect to={{pathname:"/periode"}}/>)
        }
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div>
                        <p className="pPeriode">{Trad("txt_country")}</p>
                        <h1 className="titlePeriode">{Trad("txt_country_h1")}</h1>
                    </div>

                    <div className="divCarou">
                        <Carousel responsive={responsive}>
                            {
                                this.state.countries.map((pack:any, i:number)=>(
                                    <div className="divCard" key={i} >
                                        <div className="card text-center cardCust shadow">
                                            <div className="divImgCard">
                                                <img className="card-img-top" id="packImage" src={pack.flag} alt=""/>
                                            </div>
                                            <div className="card-body">
                                                <h2 title={pack.name.fr} className="card-title font-weight-bold text-truncate">{pack.name.fr}</h2>

                                                <div>
                                                    <button onClick={this.handleChangeStart} value={pack.id} className="btn btnCountry font-weight-bold">{Trad("txt_button_country")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </Carousel>
                    </div>
                </div>
            </div>
        )
    }
}