import React, { Component } from 'react';
import Header from './Header';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import $ from "jquery";
import { Redirect } from 'react-router-dom';

import '../css/budget.css';
import { Trad } from '../trad-lib/index.bin';

export default class Budget extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.onChangeDevise = this.onChangeDevise.bind(this);
        this.onChangeInputTot = this.onChangeInputTot.bind(this);
        this.onChangeInputBud = this.onChangeInputBud.bind(this);
        this.state = {
            devise: JSON.parse(localStorage.getItem("devise") || '0'),
            priceTot: JSON.parse(localStorage.getItem("priceTot") || null),
            supports: JSON.parse(localStorage.getItem("tabSuppSelected") || '[]'),
            budgetPourcent: JSON.parse(localStorage.getItem("budgetPourcent") || null),
            btnDisabled: true
        }
    }
    onChangeDevise(event){
        if(event.target.value === "€"){
            this.setState({
                devise: "€"
            },()=>{
                localStorage.setItem("devise",JSON.stringify("€"))
            });
        } else {
            this.setState({
                devise: "F CFA"
            },()=>{
                localStorage.setItem("devise",JSON.stringify("F CFA"))
            });
        }
    }
    onChangeInputTot(event){
        let btnBool = true;
        if(event.target.value > 0){
            btnBool = false;
        }
        this.setState({
            priceTot: event.target.value,
            btnDisabled: btnBool
        },()=>{
            localStorage.setItem("priceTot",JSON.stringify(event.target.value))
        });
    }
    onChangeInputBud(event){
        $('.inputBudListe').removeClass('inputBudListeRed');
        $('.divDevise').removeClass('divDeviseRed');
        const supports = this.state.supports;
        const idSupp = parseInt(event.target.id);
        let value = event.target.value;
        if(value === ""){
            value = 0;
        }
        let budgetPourcent = 0;
        supports.forEach(supp => {
            if(idSupp === supp.STCK_ID){
                supp.budget = value;
            }
            if(supp.budget){
                budgetPourcent += parseInt(supp.budget);
            }
        });
        this.setState({
            budgetPourcent: budgetPourcent,
            supports: supports
        },()=>{
            localStorage.setItem("budgetPourcent",JSON.stringify(budgetPourcent))
            localStorage.setItem("tabSuppSelected",JSON.stringify(supports))
        });
    }
    onClickBtnErrorBud(){
        $('.divDisplayErrorBud').css('display', 'none');
    }
    componentDidMount(){
        if(this.state.devise === 0){
            this.setState({
                devise: "€"
            },()=>{
                localStorage.setItem("devise",JSON.stringify("€"))
            })
        }
        let btnBool = true;
        if(JSON.parse(localStorage.getItem("priceTot") || '0') > 0){
            btnBool = false;
        }
        this.setState({
            btnDisabled: btnBool
        });
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div className="divTitle">
                        <p className="pResumePack">{Trad("budget")}</p>
                        <h1 className="titleResumePack">{Trad("budget_h1")}</h1>
                    </div>
                    <div className="d-flex justify-content-between align-items-center divTitBud">
                        <div>
                            <h2 className="titBud">{Trad("total_budget")}</h2>
                        </div>
                        <div className="d-flex" id="divBudTot">
                            <div>
                                <input onChange={this.onChangeInputTot} value={this.state.priceTot === null?"":this.state.priceTot} className="inputBud" type="text"/>
                            </div>
                            <div>
                                <select onChange={this.onChangeDevise} value={this.state.devise} name="devise" id="devise">
                                    <option value="€">€</option>
                                    <option value="F CFA">F CFA</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="divDisplayErrorBud justify-content-center">
                        <div className="divErrorBudget">
                            <div>
                                {Trad("percentage_err")}
                            </div>
                            <button onClick={this.onClickBtnErrorBud} className="btnErrorBud">OK</button>
                        </div>
                    </div>
                    <div className="d-flex divListeBud">
                        <div className="w-50 px-4 borderRightBud">
                            {
                                this.state.supports.map((supp:any, key:number)=>(
                                    (key%2) === 0 ?
                                        <div key={key} className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="divImgBud mr-3">
                                                    <img className="w-100" src={`../images/replace.jpg`} alt="logoSupp"/>
                                                </div>
                                                <div>
                                                    <div className="divStyleSuppBudBold">
                                                        {supp.STCK_LIB}
                                                    </div>
                                                    <div className="divStyleSuppBud">
                                                        {supp.Region}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center divBudgetByOne">
                                                <div>
                                                    <input className="inputBudListe" id={supp.STCK_ID} type="text" onChange={this.onChangeInputBud} value={supp.budget===0||supp.budget===undefined?"":supp.budget}/>
                                                </div>
                                                <div className="divDevise">
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div key={key}></div>
                                ))
                            }
                        </div>
                        <div className="w-50 px-4 borderLeftBud">
                            {
                                this.state.supports.map((supp:any, key:number)=>(
                                    (key%2) != 0 ?
                                        <div key={key} className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="divImgBud mr-3">
                                                    <img className="w-100" src={`../images/replace.jpg`} alt="logoSupp"/>
                                                </div>
                                                <div>
                                                    <div className="divStyleSuppBudBold">
                                                        {supp.STCK_LIB}
                                                    </div>
                                                    <div className="divStyleSuppBud">
                                                        {supp.Region}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center divBudgetByOne">
                                                <div>
                                                    <input className="inputBudListe" id={supp.STCK_ID} type="text" onChange={this.onChangeInputBud} value={supp.budget===0||supp.budget===undefined?"":supp.budget}/>
                                                </div>
                                                <div className="divDevise">
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div key={key}></div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev />
                        <ButtonContinue onDisabled={this.state.btnDisabled} budgetPourcent={this.state.budgetPourcent} />
                    </div>
                </div>
            </div>
        )
    }
}