import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import imgLogin from '../images/arbre.png';
import Header from './Header';

import '../css/conditionGenerale.css';

    export default class ConditionGenerale extends Component<any, any> {
        constructor(props : any){
            super(props);
            this.state = {

            }
        }
        render() {
            if (sessionStorage.getItem('userData')){
                return (<Redirect to={'/periode'}/>)
            }
            return (
                <div>
                    <Header />
                    <div className="d-flex divLogin">
                        <div className="divLeft">
                            <h1 className="titleCond">Conditions Générales</h1>
                            <div className="titleParaCond">
                                titre para
                            </div>
                            <div className="divCond">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quos consectetur optio, recusandae totam facere in autem ad magnam deserunt, magni amet quae nisi quod provident unde? Sed, voluptatum. Culpa!
                            </div>
                            <div className="titleParaCond">
                                titre para
                            </div>
                            <div className="divCond">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quos consectetur optio, recusandae totam facere in autem ad magnam deserunt, magni amet quae nisi quod provident unde? Sed, voluptatum. Culpa!
                            </div>
                        </div>
                        <div className="divRight">
                            <img className="imgLogin" src={imgLogin} alt=""/>
                        </div>
                    </div>
                </div>
            )
        }
    }