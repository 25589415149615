import React, { Component } from 'react'

import '../css/tradSelect.css';
import RfiContext from './RfiContext';
interface MyProps {};
interface MyState { 
    language: string
};
export default class TradSelect extends Component<MyProps, MyState> {
    static contextType = RfiContext;
    constructor(props : any){
        super(props);
        this.change = this.change.bind(this);

        this.state = {
            language:  localStorage.getItem('currentLocal') || 'fr'
        }
      }
      change(event:any) {
        this.context.handleChange(event)
      }
    render() {
        // console.log(this.context)
        return (
            <div>
                <select name="language" id="language" onChange={this.change} value={this.context.language} >
                    <option value="fr">Fr</option>
                    <option value="en">En</option>
                </select>
            </div>
        )
    }
}