
export type TradMultiLang = {
    "fr-FR": string;
    "en-US": string;
}

export interface ITradType {
    "@rid"?: string; // ID de la DB
    Type: "dynamic" | "static";
    Class?: string;
    Property?: string;
    Value?: string;
    Code?: string;
    Translations: TradMultiLang;
}

export type Locale = "fr" | "en";

/**
 * Configure les accès à la locale
 * @param _getter 
 * @param _setter 
 */
export let ConfigureInOut = (_getter: () => Locale, _setter: (locale: Locale) => any) => {
    setter = _setter;
    getter = _getter;
}

let setter: (locale: Locale) => any = undefined;
let getter: () => Locale = undefined;

/**
 * Accès à la locale courante
 */
export let GetCurrentLocale = () => {
    // console.log(localStorage.getItem('currentLocal'))
    let locale = (localStorage.getItem('currentLocal') ?? "fr") as Locale;
    if (!locale)
        console.warn(`No local found`)

    return locale;
}

/**
 * Pour changer la locale courante
 * @param locale 
 */
export let SetCurrentLocale = (locale: Locale) => {
    setter?.(locale);
}

/**
 * A utiliser dans adwone uniquement, fonction de transition de systeme
 * @param name 
 */
export let TradClassName = (name: string): string => {
    return Trad(name);
}

/**
 * Traduit un code statique
 * @param key 
 */
export let Trad = (key: string): string => {
// console.log(key)
// console.log(localStorage.getItem('currentLocal'))
    if (!key)
        return 'undefined';

    // Hack adwone, transition old key system  
    if (key.startsWith("property_")) {
        let k = key.replace("property_", "");
        let trad = TradProvider.GetInstance().Trad({ Type: "static", Property: k });
        if (!trad.startsWith("static/"))
            return trad;
    }

    // Hack adwone, transition old key system  
    if (key.startsWith("ref_") || key.startsWith("src_")) {
        let trad = TradProvider.GetInstance().Trad({ Type: "static", Class: key });
        if (!trad.startsWith("static/"))
            return trad;
    }

    let trad = TradProvider.GetInstance().Trad({ Type: "static", Code: key });
    return trad;
}

/**
 * Traduit le nom d'une propriété
 * @param prop 
 * @param classType 
 */
export function TradProp<T>(prop: (keyof T & string), classType?: new () => T) {
    return TradProvider.GetInstance().Trad({ Type: "static", Class: classType?.name, Property: prop });
}

/**
 * Traduit une valeur, dynamic
 * @param classType 
 * @param prop 
 * @param value 
 */
export function TradValue<T>(classType: (new () => T) | string, prop: keyof T & string, value: string) {
    let Class = typeof classType === "string" ? classType : classType?.name;
    return TradProvider.GetInstance().Trad({ Type: "dynamic", Class, Property: prop, Value: value });
}

export let GetTimeFormat = () => {
    switch (GetCurrentLocale()) {
        case "fr":
            return "HH:mm"
        case "en":
            return "hh:mm a"
        default:
            return "HH:mm"
    }
}

export class TradProvider {

    /** timeout in ms, after that timeout trads are refreshed */
    static Timeout: number;

    static TradGetter: () => Promise<ITradType[]>;

    private dicoTrads: { [prop: string]: ITradType } = {};
    private allTrads: ITradType[];
    private static _provider: TradProvider;

    static GetInstance(): TradProvider {
        if (!TradProvider._provider)
            TradProvider._provider = new TradProvider();
        return TradProvider._provider;
    }

    static GetCode(trad: Partial<ITradType>) {
        if (!trad)
            return null;

        if (!trad.Class && !trad.Property && !trad.Value)
            return trad.Code;

        trad.Code = `${trad.Type}/${trad.Class}/${trad.Property}`;
        if (trad.Type === "dynamic")
            trad.Code += `/${trad.Value}`;

        return trad.Code;
    }

    TradCode(code: string, nullIfNotFount: boolean = false) {
        if (!code) {
            console.error(`code undefined`);
            return "undefined";
        }
        /** mapping temporaire */
        let mapping: { [prop in Locale]: string } = {
            fr: 'fr-FR',
            en: 'en-US'
        }
        // console.log(localStorage.getItem('currentLocal'))
        let locale = GetCurrentLocale();
        let mapped = mapping[locale];
        // console.log(locale)
        if (!mapped) {
            console.error(`mapping locale not found for locale: ${locale}`);
            return code;
        }

        let trad = this.dicoTrads[code]?.Translations?.[mapped];
        if (!trad && !nullIfNotFount) {
            trad = code;
        }

        return trad;
    }

    Trad(trad: Partial<ITradType>) {
        // console.log(trad);
        // console.log(localStorage.getItem('currentLocal'));
        if (!trad) {
            console.error(`trad undefined`);
            return "undefined";
        }

        switch (trad.Type) {
            case "dynamic":

                return this.TradCode(TradProvider.GetCode(trad), true);

            case "static":

                let code = TradProvider.GetCode(trad);
                let tradBase = this.TradCode(code, true);

                if (tradBase)
                    return tradBase;

                if (trad.Property) {
                    let codeProp = TradProvider.GetCode({ Type: "static", Property: trad.Property });
                    let tradProp = this.TradCode(codeProp, true);
                    if (tradProp)
                        return tradProp;
                }
                return code;

            default:
                console.error(`trad with no type`);
                console.error(trad);
                return "*";
        }
    }

    async Init() {

        if (this.allTrads)
            return;

        let getTrads = async () => {
            this.allTrads = await TradProvider.TradGetter() ?? [];
            this.allTrads?.forEach(t => {
                if (!t?.Code)
                    return;

                if (this.dicoTrads[t.Code]) {
                    //console.warn(`Trad code already exists: ${t.Code}`);
                }

                this.dicoTrads[t.Code] = t;
            });
        }

        await getTrads();

        if (TradProvider.Timeout)
            setInterval(getTrads, TradProvider.Timeout);

        console.log(`Translation init OK`);
    }
}

