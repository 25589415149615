import React, { Component } from 'react';
import Header from './Header';
import ButtonPrev from './ButtonPrev';
import ModalCoordonees from './ModalCoordonees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormTextArea } from './form-components';
import { annonceurValidator } from './Validators';
import { Redirect } from 'react-router-dom';
import $ from "jquery";

import '../css/resumeUser.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';

export default class ResumeUser extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props : any){
        super(props);
        this.onClickModalCoor = this.onClickModalCoor.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleSubmitVal = this.handleSubmitVal.bind(this);
        this.state = {
            showModal: false,
            userData: JSON.parse(sessionStorage.getItem('userData') || '[]'),
            redirect: false,
            created: false
        };
    }
    onClickModalCoor(){
        this.setState({
            showModal: true
        });
    }
    handleModalShow(){
        this.setState({
            showModal: false,
            userData: JSON.parse(sessionStorage.getItem('userData') || '[]'),
        });
    }
    handleSubmitVal(data:any){
        let rfi = JSON.parse(localStorage.getItem('rfi') || '[]');
        const user = 
        rfi = {...rfi, 
            annoncer : data.annoncer,
            productName : data.product,
            comment : data.comment,
            userEmail : this.state.userData.data.email
        }

        axios
        .post(`${this.baseUrl}/rfi`, rfi)
        .then((response) => {
          if(response.data.error){
            console.log(response)
          }else{
            this.setState({
                created: true
            });
            this.sendRfpMail(rfi);
          }

         }).catch((err) => console.log(err.message));
        this.setState({
            redirect: true
        });
    }

    sendRfpMail(rfi:any){
        axios
        .post(`https://maildev.test.rfp.adwanted.com/canal/sendNotif`, rfi)
        .then((response) => {
            console.log(response.data);
            if(response.data.error){
                $('.messErrorLogin').css('display', 'block');
            }else{
                console.log(response.data);
            }
        }).catch((err) => {
            console.log(err)
            $('.messErrorLogin').css('display', 'block')
        });
    }
    
    onChangeAnnonceur(event){
        if(event.target.value.length === 0){
            $('.completed').addClass('btnValDisabled');
        } else {
            $('.completed').removeClass('btnValDisabled');
        }
    }
    componentDidMount(){
        $('.k-form').addClass('heightTot');
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        if(this.state.redirect){
            const path = window.location.pathname;
            const rediPath = "/" + path.split('/')[1] + "/validation";
            return (<Redirect to={{pathname:rediPath}}/>)
        }
        return (
            <div>
                <ModalCoordonees showModal={this.state.showModal} onCurrentModalShow={this.handleModalShow} />
                <Header />
                <div className="paddingBody">
                    <Form
                        onSubmit={this.handleSubmitVal}
                        render={(formRenderPropsVal: any) => (
                            <FormElement>
                                <div className="divTitle">
                                    <p className="pResumePack">{Trad("resume")}</p>
                                    <h1 className="titleResumePack">{Trad("resume_user_h1")}</h1>
                                </div>
                                <div className="d-flex divUserRes">
                                    <div className="w-50 marginRight">
                                        <fieldset className={'k-form-fieldset mt-0'}>
                                                    <Field
                                                        id={'annonceur'}
                                                        name={'annoncer'}
                                                        label={Trad("annoncer") + '*'}
                                                        placeholder={Trad("annoncer")}
                                                        component={FormInput}
                                                        onChange={this.onChangeAnnonceur}
                                                        validator={annonceurValidator}
                                                    />
                                                    <Field
                                                        id={'produit'}
                                                        name={'product'}
                                                        label={Trad("product")}
                                                        placeholder={Trad("product")}
                                                        component={FormInput}
                                                    />
                                                    <Field
                                                        id={'commentaires'}
                                                        name={'comment'}
                                                        label={Trad("comment")}
                                                        placeholder={Trad("comment")}
                                                        component={FormTextArea}
                                                    />
                                            <div className="divChampsOblig">
                                                * {Trad("req_field")}
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="w-50 marginLeft borderResume d-table">
                                        <div className="d-flex">
                                            <div className="divTitleReus">
                                                <h2 className="hResume">{Trad("contact_details")}</h2>
                                            </div>
                                            <div>
                                                <button className="btnCoor" type="button" onClick={this.onClickModalCoor}><FontAwesomeIcon className="penStyle" icon={faPen} /></button>
                                            </div>
                                        </div>
                                        <div className="d-flex marginBottom">
                                            <div className="divIcon"><FontAwesomeIcon icon={faUser} /></div>
                                            <div className="divLineH">
                                                <div className="labelDate">{Trad("user_title")}</div>
                                                <div className="dateStyle">{this.state.userData.data.firstName} {this.state.userData.data.lastName}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex marginBottom">
                                            <div className="divIcon"><FontAwesomeIcon icon={faBuilding} /></div>
                                            <div className="divDateVal divWi">
                                                <div className="labelDate">{Trad("company")}</div>
                                                <div className="dateStyle">{this.state.userData.data.society}</div>
                                            </div>
                                            <div className="divDateVal">
                                                <div className="labelDate">{Trad("poste")}</div>
                                                <div className="dateStyle">{this.state.userData.data.position}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex marginBottom">
                                            <div className="divIcon"><FontAwesomeIcon icon={faEnvelope} /></div>
                                            <div className="divLineH">
                                                <div className="labelDate">Email</div>
                                                <div className="dateStyle">{this.state.userData.data.email}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="divIcon"><FontAwesomeIcon icon={faPhoneAlt} /></div>
                                            <div className="divLineH">
                                                <div className="labelDate">{Trad("phone")}</div>
                                                <div className="dateStyle">{this.state.userData.data.telephone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex divBtnRfp">
                                    <ButtonPrev redirect={"/package/resume"} />
                                    <div className="divBtn">
                                        <button className="btnSuiv completed btnValDisabled" type={'submit'} disabled={!formRenderPropsVal.allowSubmit}>{Trad("validate")} &nbsp; <FontAwesomeIcon className="iconCont" icon={faCheck} /></button>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            </div>
        )
    }
}