import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import imgLogin from '../images/arbre.png';
import Header from './Header';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput } from './form-components';
import { emailValidator, mdpValidator } from './Validators';
import $ from "jquery";

import '../css/passForget.css';

    export default class ForgetPass extends Component<any, any> {
        constructor(props : any){
            super(props);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.state = {
                redirect:false
            }
        }
        handleSubmit(event){
            const email =  event.email;
            axios
            // .post(`http://adw-docker2.adwanted.group:3007/auth/canal/emailExist`,{email})
            .post(`https://demo.service.rfp.adwanted.com/auth/canal/emailExist`,{email})
            .then((response) => {
              
                this.sendResetPass(response.data.token);
                this.setState({redirect:true})
            }).catch((err) => {
                console.log(err.response.data.message)
                $('.messErrorLogin').css('display', 'block').text(err.response.data.message)

            });
        }
        sendResetPass(token){
            axios
            .post(`https://maildev.test.rfp.adwanted.com/canal/sendResetPass`,{token})
            // .post(`http://adw-docker2.adwanted.group:3010/canal/sendResetPass`,{token})
            .then((response) => {
                console.log(response.data)
            }).catch((err) => {
                console.log(err.response)
                $('.messErrorLogin').css('display', 'block').text(err.response.data.message)

            });
        }
        render() {
            if (sessionStorage.getItem('userData')){
                return (<Redirect to={'/periode'}/>)
            }
            if (this.state.redirect){
                return (<Redirect to={'/confirmpassword'}/>)
            }
            return (
                <div>
                    <Header />
                    <div className="d-flex divLogin">
                        <div className="divLeft">
                            <h1 className="titleInsc">Mot de passe oublié</h1>
                            <Form
                                onSubmit={this.handleSubmit}
                                render={(formRenderProps: any) => (
                                    <FormElement>
                                        <fieldset className={'k-form-fieldset'}>
                                            <Field
                                                id={'email'}
                                                name={'email'}
                                                className={'labelPosition'}
                                                label={'Adresse mail'}
                                                type={'email'}
                                                placeholder={'Email'}
                                                component={FormInput}
                                                validator={emailValidator}
                                            />
                                            <div className="messErrorLogin"></div>
                                            <div className="divInscSubmit">
                                                <button name="btnSubmit" type={'submit'} disabled={!formRenderProps.allowSubmit} className="btnSubmitLogin">Envoyer</button>
                                            </div>
                                        </fieldset>
                                    </FormElement>
                                )}
                            />
                        </div>
                        <div className="divRight">
                            <img className="imgLogin" src={imgLogin} alt=""/>
                        </div>
                    </div>
                </div>
            )
        }
    }